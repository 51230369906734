import React from 'react';
import './css/App.css';
import UserForm from './userForm';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import logo from './img/welcomeToMilaLogo.png';

function App() {

  // Import the useMsal hook
  const { instance, accounts } = useMsal();

  // Function to handle the login process
  const handleLogin = () => {
      instance.loginRedirect(loginRequest).catch(e => {
          console.error(e);
      });
  };

  // Function to handle the logout process
  const handleLogout = () => {
      instance.logoutRedirect().catch(e => {
          console.error(e);
      });
  };

// set the justify content to center
document.documentElement.style.setProperty('--dynamic-justify-content', 'center');

  // Return the app UI
  return (
    <div className="App">
        {accounts.length > 0 ? (
            <>
                <p />
                <h1>Mila JARVIS - Case Manager</h1>
                <button onClick={handleLogout}>Logout</button>
                <p />
                <UserForm />
            </>
        ) : (
            <div>
                <img src={logo} alt="Logo" style={{ width: '200px', height: '124px' }} />
                <button onClick={handleLogin}>Click this button to Log in to Mila</button>
            </div>
        )}
    </div>
  );
}

export default App;
